<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader :btn="btn" :isShow="isShow" :strForm="{ status: formStatus }" @closeEdit="closeEdit" @openEdit="openEdit" @submit="submit('sollForm')" />
      <div class="vd_export" v-if="isShow">
        <el-button type="primary" size="small" @click="initData()">刷新</el-button>
      </div>
      <div class="vd_export2 vg_mr_8">
        <span style="margin-right: 2px">收款单编号:</span>
        <el-input size="mini" style="width: 150px" disabled v-model="soll_no" show-word-limit></el-input>
      </div>
    </div>
    <DynamicForm
      v-bind="$attrs"
      :total-width="mainForm.totalWidth"
      :split="mainForm.split"
      :form-list="mainForm.formProperties"
      ref="dynamicForm"
      class="dynamicForm"
      :is-show="isShow"
      :computed-data="{
        // tran_nos: tran_nos,
        // scon_nos: scon_nos,
        // scon_cust_nos: scon_cust_nos,
        soll_total: soll_total,
        soll_claitotal: soll_claitotal,
        soll_chartotal: soll_chartotal
      }"
    />
    <div class="vd_dash"></div>
    <el-row class="vg_mb_5">
      <el-col>
        <div class="vg_mb_16">
          <el-button :disabled="isShow" type="primary" plain size="small" @click="openCollect">导入收款信息</el-button>
          <el-button :disabled="isShow" type="danger" plain size="small" @click="deleteCollect">删除</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <DynamicUTable
          ref="sollEdit"
          :form="sollForm"
          :tableData="subTableData"
          :columns="sollSubTableProperties"
          :isShow="isShow"
          :needPagination="false"
          :needSearch="false"
          :row-click="(row, column, event) => (currentRow = row)"
          :computed-data="{ soll_netrece: soll_netrece }"
          :need-all-column-summary="true"
          :showSummary="true"
          :skipSummaryIndex="[1, 2, 3, 4, 5, 12]"
          @selection-change="handleSelectionChange"
        />
      </el-col>
    </el-row>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser v-if="stffForm.stff_name" ref="userData" :isCookie="false" :stffForm="stffForm"></inputUser>
      </el-col>
    </el-row>
    <el-dialog title="导入应收款信息" width="70%" :visible.sync="dialogTableVisible">
      <CollecList ref="CollecList" :searchForm="searchForm" @childChanel="dialogTableVisible = false" @childConfirm="childConfirm"></CollecList>
    </el-dialog>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { sollAPI } from '@api/modules/soll';
import { cpttAPI } from '@api/modules/comptitle';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import { BigNumber } from 'bignumber.js';
import CollecList from './Component/CollecList.vue';
import { keep4Decimal } from '@assets/js/regExUtil';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { sollMainFormProperties, sollSubTableProperties } from '@/views/FinanceManagement/SollManage/soll';
import { deleteTableRow, getArrayIds, objectArrayReduce } from '@assets/js/arrayUtils';
import { changeStaffForm } from '@api/public';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SollEditMain',
  components: {
    DynamicForm,
    DynamicUTable,
    editHeader,
    inputUser,
    CollecList
  },
  data() {
    return {
      sollForm: {},
      searchForm: {},
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: false,
      CompanyList: [],
      cpttList: [],
      bankList: [],
      dialogTableVisible: false,
      dialogTableVisible1: false,
      selectList: [],
      subTableData: [],
      subTableMap: new Map(),
      sollSubTableProperties: sollSubTableProperties,
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(sollMainFormProperties)
      },
      cptt_aname: '',
      cptt_bname: '',
      soll_id: '',
      soll_no: '',
      segi_id: '',
      formStatus: '',
      currentRow: {}
    };
  },
  computed: {
    // // 出运发票号合计
    // tran_nos() {
    //   let tran_nos = getArrayIds(this.subTableData, 'tran_no').toString();
    //   this.mainForm.formProperties.find(({ label }) => label === '出运发票号').model = tran_nos;
    //   return tran_nos;
    // },
    // // 销售合同号合计
    // scon_nos() {
    //   let scon_nos = getArrayIds(this.subTableData, 'scon_no').toString();
    //   this.mainForm.formProperties.find(({ label }) => label === '销售合同号').model = scon_nos;
    //   return scon_nos;
    // },
    // // 客户订单号合计
    // scon_cust_nos() {
    //   let scon_cust_nos = getArrayIds(this.subTableData, 'scon_cust_no').toString();
    //   this.mainForm.formProperties.find(({ label }) => label === '客户订单号').model = scon_cust_nos;
    //   return scon_cust_nos;
    // },
    // 收款总额 = 本次收款金额之和 + 银行费用之和
    soll_total() {
      let soll_reced = objectArrayReduce(this.subTableData, 'soll_reced');
      let soll_rece_char = objectArrayReduce(this.subTableData, 'soll_rece_char');
      let temp = new BigNumber(soll_reced);
      let find = this.mainForm.formProperties.find(({ prop }) => prop === 'soll_total');
      if (!temp.isFinite()) {
        find.model = 0;
        return '自动计算';
      }
      find.model = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 银行费用  = 子表银行费用之和
    soll_chartotal() {
      let soll_rece_char = objectArrayReduce(this.subTableData, 'soll_rece_char', 2);
      this.mainForm.formProperties.find(({ prop }) => prop === 'soll_chartotal').model = soll_rece_char;
      return soll_rece_char;
    },
    // 领用金额 = 子表实收金额之和
    soll_claitotal() {
      let soll_netrece = objectArrayReduce(this.subTableData, 'soll_netrece', 2);
      this.mainForm.formProperties.find(({ prop }) => prop === 'soll_claitotal').model = soll_netrece;
      return soll_netrece;
    },
    // 子表实收金额 = 子表本次收款金额 - 子表银行费用 + 收款差额
    soll_netrece() {
      let { soll_reced, soll_rece_char, soll_diffprice } = this.currentRow;
      let temp = new BigNumber(Number(soll_reced)).minus(Number(soll_rece_char)).plus(Number(soll_diffprice));
      if (!temp.isFinite()) {
        this.currentRow.soll_netrece = 0;
        return '自动计算';
      }
      this.currentRow.soll_netrece = temp.toFixed(2);
      //计算人民币金额 sta
      let formModel = this.$refs.dynamicForm.getFormModel();
      let rmbPrice2 = new BigNumber(Number(this.currentRow.soll_netrece) * Number(formModel.tran_usdrate));
      this.currentRow.soll_rmbprice = rmbPrice2.isFinite() ? rmbPrice2.toFixed(2) : null;
      if (this.subTableData.length > 1) {
        let rmbPriceAll = objectArrayReduce(
          this.subTableData.filter((element, index) => index !== this.subTableData.length - 1),
          'soll_rmbprice'
        );
        this.subTableData[this.subTableData.length - 1].soll_rmbprice = new BigNumber(formModel.scon_rmbtotal - rmbPriceAll).toFixed(2);
      }
      //计算人民币金额 end
      return temp.toFixed(2);
    }
  },
  async mounted() {
    await this.initData();
  },
  methods: {
    keep4Decimal,
    async initData() {
      this.loadingFlag = true;
      await this.getCptt();
      await this.getSollInfo();
      setTimeout(() => this.changeProperties(), 100);
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(err => console.error(err));
    },
    changeProperties() {
      let model = this.mainForm.formProperties.find(({ label }) => label === '收款抬头').model;
      this.bankList = this.cpttList.find(({ cptt_id }) => cptt_id === model)?.cptt_bank_list || [];

      let cptt = Array.from(this.cpttList, ({ cptt_id, cptt_name }) => {
        return { label: cptt_name, value: cptt_id };
      });

      let huikuantaitou = this.mainForm.formProperties.find(({ label }) => label === '汇款抬头');
      huikuantaitou.options = cptt;
      this.cptt_aname = this.cpttList.find(({ cptt_id }) => cptt_id === cloneDeep(huikuantaitou.model))?.cptt_name;
      huikuantaitou.change = val => {
        this.cptt_aname = this.cpttList.find(({ cptt_id }) => cptt_id === val)?.cptt_name;
      };
      let shoukuantaitou = this.mainForm.formProperties.find(({ label }) => label === '收款抬头');
      shoukuantaitou.options = cptt;
      this.cptt_bname = this.cpttList.find(({ cptt_id }) => cptt_id === cloneDeep(shoukuantaitou.model))?.cptt_name;
      shoukuantaitou.change = this.cpttChange;

      let shoukuanyinhang = this.mainForm.formProperties.find(({ label }) => label === '收款银行');
      shoukuanyinhang.options = Array.from(this.bankList, ({ cptt_bank_name }) => {
        return { label: cptt_bank_name, value: cptt_bank_name };
      });
      shoukuanyinhang.change = this.bankChange;
    },
    // 获取表单信息
    getSollInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      getNoCatch(sollAPI.getSollById, { soll_id: props.form_id }).then(({ data }) => {
        this.btn = data.btn;
        if (data.form.autoInc) this.btn.edit = false;
        changeStaffForm(this.stffForm, data.form);
        this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
        this.mainForm.formProperties.find(({ label }) => label === '汇款客户').inputAppend = data.form.cust_group;
        this.searchForm.cust_id = data.form.cust_id;
        this.searchForm.cust_group = data.form.cust_group;
        this.soll_id = data.form.soll_id;
        this.soll_no = data.form.soll_no;
        this.segi_id = data.form.segi_id;
        this.formStatus = data.form.status;
        this.subTableData = data.form.soll_rece_list;
        this.loadingFlag = false;
        //用友抓取后不可反生效不可编辑
        this.$emit('isYongYou', false);
        this.mainForm.formProperties.find(({ prop }) => prop === 'yongYouStatus').model = '未抓取';
        this.mainForm.formProperties.find(({ prop }) => prop === 'yongYouStatus').show = false;
        this.subTableData.forEach(item => {
          if (item.nap_CloseBill_SK_Status === 1) {
            this.btn.edit = false;
            this.$emit('isYongYou', true);
            this.mainForm.formProperties.find(({ prop }) => prop === 'yongYouStatus').show = true;
            this.mainForm.formProperties.find(({ prop }) => prop === 'yongYouStatus').model = '已抓取';
          }
        });
      });
    },
    //提交表单
    submit(formName) {
      this.$confirm('提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable1 = await this.$refs.sollEdit.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable1) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable1) tempStr += `子表-${dynamicUTable1}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    // 保存
    saveInfo() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.cptt_aname = this.cptt_aname;
      formModel.cptt_bname = this.cptt_bname;
      formModel.soll_id = this.soll_id;
      formModel.soll_rece_list = this.subTableData.concat(Array.from(this.subTableMap.values()));
      // 出运发票号合计
      formModel.tran_nos = getArrayIds(this.subTableData, 'tran_no').toString();
      // 销售合同号合计
      formModel.scon_nos = getArrayIds(this.subTableData, 'scon_no').toString();
      // 客户订单号合计
      formModel.scon_cust_nos = getArrayIds(this.subTableData, 'scon_cust_no').toString();
      formModel.segi_id = this.segi_id;
      postNoCatch(sollAPI.editSoll, formModel).then(({ data }) => {
        this.$message.success('保存成功!');
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
      });
    },
    openCollect() {
      this.dialogTableVisible = true;
      this.searchForm.cptt_aid = this.mainForm.formProperties[3].model;
      setTimeout(() => {
        this.$refs.CollecList.initData();
      }, 300);
    },
    // 选择子表值
    handleSelectionChange(val) {
      this.selectList = JSON.parse(JSON.stringify(val));
    },
    // 删除内容
    deleteCollect() {
      if (this.selectList.length < 1) return this.$message.warning('至少选择一条数据');
      let length = this.selectList.filter(item => item.operation_status === 1).length > 0;
      if (length) {
        return this.$message.warning('选中的数据已被锁定不可被删除！');
      }
      let { tableData, map } = deleteTableRow('soll_rece_id', this.subTableData, this.subTableMap, this.selectList);
      this.subTableData = tableData;
      this.subTableMap = map;
      this.computeRmbPrice();
    },
    // 公司回显银行选项
    cpttChange(val) {
      for (let i = 0; i < this.cpttList.length; i++) {
        if (this.cpttList[i].cptt_id === val) {
          this.bankList = this.cpttList[i].cptt_bank_list;
          this.cptt_bname = this.cpttList[i].cptt_name;
          let shoukuanyinhang = this.mainForm.formProperties.find(({ label }) => label === '收款银行');
          let yinhangzhanghao = this.mainForm.formProperties.find(({ label }) => label === '银行账号');
          shoukuanyinhang.options = Array.from(this.bankList, ({ cptt_bank_name }) => {
            return { label: cptt_bank_name, value: cptt_bank_name };
          });
          if (this.cpttList[i].cptt_bank_list.length > 0) {
            shoukuanyinhang.model = this.bankList[0].cptt_bank_name;
            this.bankChange(this.bankList[0].cptt_bank_id);
          } else {
            shoukuanyinhang.model = '';
            yinhangzhanghao.model = '';
          }
        }
      }
    },
    // 回显银行账户
    bankChange(val) {
      let yinhangzhanghao = this.mainForm.formProperties.find(({ label }) => label === '银行账号');
      for (let i = 0; i < this.bankList.length; i++) {
        if (this.bankList[i].cptt_bank_id === val) {
          yinhangzhanghao.model = this.bankList[i].cptt_bank_code;
        }
      }
    },
    //获取公司抬头
    getCptt() {
      getNoCatch(cpttAPI.getAllCpttsV1).then(({ data }) => {
        this.cpttList = data;
      });
    },
    // 添加内容
    childConfirm(val) {
      cloneDeep(val).forEach(item => {
        let originalObject = cloneDeep(this.$refs.sollEdit.originalObject);
        let mergeObj = Object.assign(originalObject, item);
        mergeObj.soll_reced = item.soll_unreced;
        mergeObj.soll_rece_char = 0;
        this.subTableData.push(mergeObj);
        setTimeout(() => (this.currentRow = mergeObj));
      });
      setTimeout(() => {
        this.computeRmbPrice();
      }, 200);
      this.dialogTableVisible = false;
    },
    //计算人民币金额
    computeRmbPrice(val, row) {
      let formModel = this.$refs.dynamicForm.getFormModel();
      this.subTableData.forEach(item => {
        let rmbPrice2 = new BigNumber(Number(item.soll_netrece) * Number(formModel.tran_usdrate));
        item.soll_rmbprice = rmbPrice2.isFinite() ? rmbPrice2.toFixed(2) : null;
      });
      if (this.subTableData.length > 1) {
        let rmbPriceAll = objectArrayReduce(
          this.subTableData.filter((element, index) => index !== this.subTableData.length - 1),
          'soll_rmbprice'
        );
        this.subTableData[this.subTableData.length - 1].soll_rmbprice = new BigNumber(formModel.scon_rmbtotal - rmbPriceAll).toFixed(2);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}

.vd_word {
  word-wrap: break-word;
  word-break: normal;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}
</style>
