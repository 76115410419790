<template>
  <div class="vg_wrapper">
    <div class="vd_button_group vg_mtb_16">
      <el-row class="vd_mar10">
        <el-col :md="16">
          <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
          <el-button size="mini" type="primary" @click="confirmIn">确认选择</el-button>
          <el-button size="mini" type="info" @click="refresh">刷新</el-button>
        </el-col>
      </el-row>
    </div>
    <DynamicUTable
      ref="multiTable"
      :table-data="tableData"
      :columns="tableProperties"
      v-loading="loadFlag"
      :totalPage="totalPage"
      :need-fixed-height="true"
      :need-pagination="false"
      :need-search="true"
      :showSummary="true"
      :summariesColumns="['soll_reced_total', 'soll_subrece']"
      @selection-change="handleSelectionChange"
      @getTableData="getProdsList"
    >
    </DynamicUTable>
  </div>
</template>

<script>
import { tranAPI } from '@/api/modules/tran';
import pubPagination from '@/components/common/pubPagination';
import { useCustMixin, useDeptMixin, useStffMixin, useSuppMixin } from '@/assets/js/mixin/initMixin';
import { importSHTableProperties } from '@/views/FinanceManagement/SollManage/soll';
import DynamicUTable from '@/components/table/DynamicUTable.vue';

export default {
  name: 'CollecList',
  components: {
    DynamicUTable,
    pubPagination
  },
  props: {
    searchForm: {
      type: Object,
      required: true
    }
  },
  mixins: [useSuppMixin, useCustMixin, useStffMixin, useDeptMixin],
  data() {
    return {
      tableProperties: importSHTableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      prodTypes: [],
      contry: [],
      suppList: []
    };
  },
  methods: {
    initData() {
      this.getProdsList();
    },
    async getProdsList() {
      await (this.loadFlag = true);
      let searchForm = this.$refs.multiTable.searchForm;
      searchForm.cust_id = this.searchForm.cust_id;
      searchForm.cptt_aid = this.searchForm.cptt_aid;
      searchForm.cust_group = this.searchForm.cust_group;
      tranAPI.getTranPriceForSh(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.tran_part_id;
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.initData();
    },
    // 查询方法
    getProdsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getProdsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childChanel');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childConfirm', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    },
    refresh() {
      this.$refs.multiTable.resetFields();
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
</style>
