var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingFlag),expression:"loadingFlag"}],staticClass:"vg_wrapper"},[_c('div',{staticClass:"vd_edhea vg_button_group"},[_c('editHeader',{attrs:{"btn":_vm.btn,"isShow":_vm.isShow,"strForm":{ status: _vm.formStatus }},on:{"closeEdit":_vm.closeEdit,"openEdit":_vm.openEdit,"submit":function($event){return _vm.submit('sollForm')}}}),(_vm.isShow)?_c('div',{staticClass:"vd_export"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.initData()}}},[_vm._v("刷新")])],1):_vm._e(),_c('div',{staticClass:"vd_export2 vg_mr_8"},[_c('span',{staticStyle:{"margin-right":"2px"}},[_vm._v("收款单编号:")]),_c('el-input',{staticStyle:{"width":"150px"},attrs:{"size":"mini","disabled":"","show-word-limit":""},model:{value:(_vm.soll_no),callback:function ($$v) {_vm.soll_no=$$v},expression:"soll_no"}})],1)],1),_c('DynamicForm',_vm._b({ref:"dynamicForm",staticClass:"dynamicForm",attrs:{"total-width":_vm.mainForm.totalWidth,"split":_vm.mainForm.split,"form-list":_vm.mainForm.formProperties,"is-show":_vm.isShow,"computed-data":{
      // tran_nos: tran_nos,
      // scon_nos: scon_nos,
      // scon_cust_nos: scon_cust_nos,
      soll_total: _vm.soll_total,
      soll_claitotal: _vm.soll_claitotal,
      soll_chartotal: _vm.soll_chartotal
    }}},'DynamicForm',_vm.$attrs,false)),_c('div',{staticClass:"vd_dash"}),_c('el-row',{staticClass:"vg_mb_5"},[_c('el-col',[_c('div',{staticClass:"vg_mb_16"},[_c('el-button',{attrs:{"disabled":_vm.isShow,"type":"primary","plain":"","size":"small"},on:{"click":_vm.openCollect}},[_vm._v("导入收款信息")]),_c('el-button',{attrs:{"disabled":_vm.isShow,"type":"danger","plain":"","size":"small"},on:{"click":_vm.deleteCollect}},[_vm._v("删除")])],1)])],1),_c('el-row',[_c('el-col',{attrs:{"md":24}},[_c('DynamicUTable',{ref:"sollEdit",attrs:{"form":_vm.sollForm,"tableData":_vm.subTableData,"columns":_vm.sollSubTableProperties,"isShow":_vm.isShow,"needPagination":false,"needSearch":false,"row-click":function (row, column, event) { return (_vm.currentRow = row); },"computed-data":{ soll_netrece: _vm.soll_netrece },"need-all-column-summary":true,"showSummary":true,"skipSummaryIndex":[1, 2, 3, 4, 5, 12]},on:{"selection-change":_vm.handleSelectionChange}})],1)],1),_c('el-row',{staticClass:"vg_mt_16 vd_bortop"},[_c('el-col',[(_vm.stffForm.stff_name)?_c('inputUser',{ref:"userData",attrs:{"isCookie":false,"stffForm":_vm.stffForm}}):_vm._e()],1)],1),_c('el-dialog',{attrs:{"title":"导入应收款信息","width":"70%","visible":_vm.dialogTableVisible},on:{"update:visible":function($event){_vm.dialogTableVisible=$event}}},[_c('CollecList',{ref:"CollecList",attrs:{"searchForm":_vm.searchForm},on:{"childChanel":function($event){_vm.dialogTableVisible = false},"childConfirm":_vm.childConfirm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }